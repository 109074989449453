.mainItems{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 80%;
    gap: 40px;
    margin: auto;
    margin: 48px auto;
}

.itemsContainer{
    display: grid;
    grid-auto-flow: column;
    width: 100%;
}

.itemOne{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 48px 0 ;
}

.itemsCard{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: justify;
    width: 40%;
    gap: 20px;
    padding: 16px;
}

.itemsCard:hover{
    transform: scale(1.02);
    transition: 0.8s;
    border: 1px solid rgb(17, 113, 135);
    border-radius: 8px;
}

.itemsCard h2{
    text-align: center;
    color:  rgb(17, 113, 135);
}

.itemsCard p {
    font-size: 1.3rem;
}

.itemsCard img{
    width: 20%;
}

button{
    width: auto;
    padding: 8px;
    height: 40px;
    background-color:rgb(17, 113, 135);
    border: 1px solid transparent;
    border-radius: 4px;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
}

.itemsCard button:hover{
    background-color:rgba(17, 113, 135, 0.9);
    transition: 0.4s;
}
@media(max-width: 830px){
    .mainItems{
        flex-flow: column;
    }
    .itemsCard{
        width: 80%;
    }
    .mainItems .itemsCard{
        width: 100%;
    }
    .itemsCard img{
        width: 30%;
    }
}