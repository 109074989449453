.dashBoardContainer{
    margin: 80px auto 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashBoardTitle{
    font-size: 2.2rem;
    text-align: center;
    color: rgb(17, 113,135);
    margin-top: 24px;
}

.dashBoardContent{
    display: grid;
    grid-template-columns: 14rem auto;
    gap: 48px;
}

.dashBoardItems{
    display: flex;
    flex-direction: column;
    align-items: center;
}

