.cardsContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: space-around;
    gap: 20px;
    margin-bottom: 48px;
}

.content{
    height: 370px;
    width: 270px;
    border-radius: 8px;
    position: relative;
    box-shadow: 2px 4px 8px rgba( 0, 0, 0, 1);
}

.cardOne .content img,
.cardTwo .content img,
.cardThree .content img,
.cardFour .content img{
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

.cardOne .content h2,
.cardTwo .content h2,
.cardThree .content h2,
.cardFour .content h2 {
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    color: white;
    text-shadow: 0px 1px 6px rgba( 0, 0, 0, 1);
}

.content .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    height: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    bottom: 24px;
    right: 0;
    left: 0;
    margin: auto;
    border-radius: 8px;
    padding: 8px;
    opacity: 0;
    border: 1px solid rgba(0, 0, 0, 0.6);
    box-shadow: 4px 4px 8px rgba( 0, 0, 0, 0.7);
}

.cardOne:hover h2,
.cardTwo:hover h2,
.cardThree:hover h2,
.cardFour:hover h2{
    opacity: 0;
    transition: 0.6s;
}

.cardOne:hover .cardContent,
.cardTwo:hover .cardContent,
.cardThree:hover .cardContent,
.cardFour:hover .cardContent{
    opacity: 1;
    transition: 1s;
}

.content .cardContent h3{
    color: rgb(17, 113, 135);
    margin: 8px 0;
    text-align: center;
}
.content .cardContent button{
    background-color: transparent;
    padding: 4px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    color: rgb(17, 113, 135);
    font-size: 1.2rem;
}
.content .cardContent button:hover{
    border: 1px solid rgb(17, 113, 135);
    transition: 0.4s;
}


@media(max-width: 1200px){
    .content{
        height: 350px;
        width: 250px;
    }
}

@media(max-width: 1130px){
    .content{
        height: 330px;
        width: 230px;
    }
}

@media(max-width: 1050px){
    .content{
        height: 310px;
        width: 210px;
    }
}

@media(max-width: 930px){
    .content{
        height: 290px;
        width: 190px;
    }
}

@media(max-width: 840px){
    .cardsContainer{
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        margin: auto;
    }
    .content{
        height: 450px;
        width: 350px;
    }
}

@media (max-width: 450px) {
    .content{
        height: 350px;
        width: 250px;
    }
}
