.mainImage{
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../assets/loquehacemos.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 44vh;
}

.mainImage h1 {
    color: rgb(255, 255, 255);
    text-shadow: 0px 1px 6px rgba( 0, 0, 0, 1);
    width: 100%;
    font-size: 2.2rem;
    text-align: center;
}

@media(max-width: 800px){
    .mainImage h1{
        font-size: 2rem;
    }
}