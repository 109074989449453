.blogSection{
    margin: 100px auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    height: auto;
}
.blogTitle{
    text-align: center;
    color: rgb(17, 113, 135);
    font-size: 2.2rem;
}
