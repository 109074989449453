.items{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 90vh;
}

.item{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.item2{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.item3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.mainCircle p{
    font-weight: 600;
    font-size: 1.1rem;
    text-align: start;
}

.item img,
.item2 img,
.item3 img{
    height: 120px;
    border: 2px solid black;
    padding: 20px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 0px 12px 0px rgba(0,0,0,0.6);
    -webkit-box-shadow: 2px 0px 12px 0px rgba(0,0,0,0.6);
    -moz-box-shadow: 2px 0px 12px 0px rgba(0,0,0,0.6);
}

.mainCircle{
    margin: 60px 0;
    position: absolute;
    left: 50%; /* Ajusta según tu diseño */
    top: 40%; /* Ajusta según tu diseño */
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 16px solid #e8e8e8;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    box-shadow: 2px 0px 12px 0px rgba(0,0,0,0.6);
    -webkit-box-shadow: 2px 0px 12px 0px rgba(0,0,0,0.6);
    -moz-box-shadow: 2px 0px 12px 0px rgba(0,0,0,0.6);
}

.secondCircle{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 50%;
    border: 2px solid #e8e8e8;
    padding: 12px;
    border-radius: 50%;
    box-shadow: 2px 0px 12px 0px rgba(0,0,0,0.4);
    -webkit-box-shadow: 2px 0px 12px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 2px 0px 12px 0px rgba(0,0,0,0.4);
}

.secondCircle img{
    height: 80%;
    width: 100%;
    margin: auto;
}

.pOne{
    position: absolute;
    top: 20px;
    left: -210px;
}

.pTwo{
    position: absolute;
    top: 20px;
    right: -280px;
}

.pThree{
    position: absolute;
    right: -410px;
    top: 198px;
}
.pFour{
    position: absolute;
    top: 198px;
    left: -270px;
    text-align: end;
}
.pFive{
    position: absolute;
    top: 380px;
    right: -220px;
}

.pSix{
    position: absolute;
    top: 380px;
    left: -280px;
}

.one{
    position: absolute;
    top: -20px;
    left: 40px;
}

.two{
    position: absolute;
    top: -20px;
    right: 40px;
}

.three{
    position: absolute;
    top: 148px;
    right: -60px;
}

.four{
    position: absolute;
    top: 148px;
    left: -60px;
}

.five{
    position: absolute;
    top: 330px;
    right: -20px;
}

.six{
    position: absolute;
    top: 330px;
    left: -20px;
}

.seven{
    position: absolute;
    top: 420px;
}

.item img:hover,
.item2 img:hover,
.item3 img:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.6s;
    border: 4px solid rgba(25, 195, 232, 0.8);
}

@media (max-width: 1350px){
    .mainCircle{
        width: 450px;
        height: 450px;
    }
    .pOne{
        left: -230px;
    }
    .one{
        left: -130px;
    }
    .pTwo{
        right: -300px;
    }
    .two{
        right: -130px;
    }
    .pThree{
        right: -420px;
    }
    .three{
        left: -220px;
    }
    .pFour{
        left: -280px;
    }
    .four{
        left: 210px;
    }
    .pFive{
        top: 350px;
    }
    .five{
        top:300px;
        left: 160px;
    }
    .pSix{
        top: 350px;
    }
    .six{
        top: 300px;
        left: -170px;
    }

    .seven{
        top: 380px;
    }
    .item,
    .item2,
    .item3{
        width: 100%;
        text-align: center;
    } 
}

@media(max-width: 1275px){
    .mainCircle{
        width: 400px;
        height: 400px;
    }
    .item img,
    .item2 img,
    .item3 img{
        height: 100px;
    }
    .pOne{
        left: -220px;
    }
    .one{
        left: -100px;
    }
    .pTwo{
        right: -280px;
    }
    .two{
        right: -100px;
    }
    .pThree{
        top: 180px;
        right: -410px;
    }
    .three{
        top: 140px;
        left: 190px;
    }
    .pFour{
        top: 180px;
        left: -260px;
    }
    .four{
        left: -190px;
        top: 140px;
    }
    .pFive{
        top: 330px;
        right: -200px;
    }
    .five{
        top:280px;
        left: 130px;
    }
    .pSix{
        top: 330px;
        left: -280px;
    }
    .six{
        top: 280px;
        left: -150px;
    }

    .seven{
        top: 340px;
    }

} 

@media(max-width:1000px){
    p{
        font-size: 0.8rem;
    }
    .pOne{
        left: -190px;
        width: 60%;
        text-align: end;
    }
    .pTwo{
        width: 60%;
        right: -190px;
    }
    .pThree{
        width: 60%;
        right: -290px;
    }   
    .pFour{
        width: 60%;
        left: -280px;
    }
    .pFIve{
        width: 60%;
    }
    .pSix{
        width: 60%;
        text-align: end;
        left: -240px;
    }
}

@media(max-width:570px){
    
    .mainCircle{
        width: 300px;
        height: 300px;
    }
    .item img,
    .item2 img,
    .item3 img{
        width: 70px;
        height: 70px;
    }
    p{
        font-size: 1rem;
    }
    .pOne{
        top: -10px;
        left: -140px;
    }
    .one{
        left: -70px;
    }
    .pTwo{
        top: -10px;
        right: -140px;
    }
    .two{
        left: 70px;
    }
    .pThree{
        top: 90px;
        right: -210px;
    }
    .three{
        top: 80px;
        left: 140px;
    }
    .pFour{
        top: 90px;
        left: -210px;
    }
    .four{
        top: 80px;
        left: -140px;
    }
    .pFive{
        top: 220px;
        right:-190px ;
    }
    .five{
        top: 190px;
        left: 110px
    }
    .pSix{
        top: 220px;
        left: -180px;
    }
    .six{
        top: 190px;
        left: -110px;
    }
    .seven{
        top: 240px;
    }

}

@media(max-width: 570px){
    .mainCircle{
        width: 250px;
        height: 250px;
    }
    .item p,
    .item2 p,
    .item3 p{
        font-size: 0.9rem;
    }

    .one{
        left: -70px;
    }
    .two{
        left: 70px;
    }
    .pThree{
        top:80px;
        right: -170px;
    }
    .three{
        top: 70px;
        left: 110px;
    }
    .pFour{
        top: 85px;
        left: -180px;
    }
    .four{
        top: 70px;
        left: -120px;
    }
    .pFive{
        top: 190px;
    }
    .five{
        top: 160px;
        left: 80px
    }
    .pSix{
        top: 180px;
        left: -140px;
    }
    .six{
        top: 160px;
        left: -80px;
    }
    .seven{
        top: 200px;
    }
}

@media(max-width:570px){
    .items{
        height: 50vh;
    }
    .mainCircle p{
        display: none;
    }
    .one{
        left: -10px;
    }
    .two{
        left: 120px;
    }
    .three{
        top: 70px;
        left: 170px;
    }
    .four{
        top: 70px;
        left: -60px;
    }
    .five{
        top: 160px;
        left: 140px
    }
    .six{
        top: 160px;
        left: -30px;
    }
    .seven{
        top: 200px;
    }
    .item,
    .item2,
    .item3{
        width: 100px
    }
}