.blogContainer{
    display: flex;
    justify-content: center;
    gap: 24px;
}

@media(max-width: 850px){
    .blogContainer{
        flex-direction: column;
        align-items: center;
    }
    
}