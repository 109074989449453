.contactSection{
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../assets/contact.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 45vh;
}

.contactContainer{
    width: 80%;
    margin: 48px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactContainer h2{
    font-size: 2.2rem;
    color: rgb( 17, 113,135 );
    text-align: center;
    margin: 24px auto;
}

.formContainer{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 40px;
}


@media(max-width: 1300px){
    .formContainer img{
        width: 500px;
        height: 400px;
    }
}

@media(max-width: 1200px){
    .formContainer img{
        width: 400px;
    }
}

@media(max-width:1000px){
    .formContainer{
        flex-direction: column;
        align-items: center;
    }
    .formContainer img{
        display: none;
    }
    .contactContainer h2{
        font-size: 2rem;
    }
}

@media(max-width: 500px){
    .contactContainer h2{
        font-size: 1.8rem;
    }
}