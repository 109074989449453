.nav {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    height: 80px;
    width: 100%;
    color: black;
    box-shadow: 0px 2px 0px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    z-index: 5;
}
.navColor1{
    background-color: rgba(255, 255, 255, 0.9);
    transition: 1s;
}

.navColor2{
    background-color: rgba(255, 255, 255, 1);
    transition: 1s;
}

.navimg img{
    height: 80px;
    margin-left: 80px;
}

.navitems {
    position:absolute;
    display: flex;
    align-items: center;
    top: -700px;
    left: -200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.active{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    margin: auto;
    padding: 30% 0;
    font-size: 1.8rem;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
}
.active a{
    color: #313131;
    text-decoration: none;
}

.navitems a{
    display: block;
    color: #313131;
    text-decoration: none;
    padding-right: 1.5rem;
}

.navitems a:hover,
.active a:hover{
    color: rgba(17, 113, 135, 0.7);
    transition: 0.8s;
}

.navitems span:hover{
    border-bottom: 2px solid rgba(17, 113, 135, 0.7);
    padding-bottom: 4px;
}

.five{
    padding: 8px;
    background-color: rgb(17, 113, 135);
    color: white;
    border-radius: 4px;
}
.five:hover{
    background-color: rgba(17, 113, 135, 0.8);
}

.lngButton{
    background-color: transparent;
    color: rgba(17, 113, 135);
    border: 1px solid rgb(17, 113, 135);
    margin: 0 4px;
    font-size: 0.8rem;
    font-weight: 600;
}
.buttonContainer{
    display: inline;
}

.loginAnchor{
    height: 100%;
    margin: auto;
}
.loginIcon{
    padding: 0;
    margin: 0;
    width: 30px;
    height: 30px;
}

@media(min-width: 1100px){
    .burger{
        display: none;
    }
    .navitems{
        position: initial;
        margin: 0;   
    }
    .navitems a{
        font-size: 1.2rem;
        display: inline;
    }
}

@media(max-width: 1100px){
    .navitems{
        display: none;
    }
    .navimg img{
        margin-left: 20px;
    }
    .buttonContainer{
        display: block;
    }
}
