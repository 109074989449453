.footer{
    background-image: linear-gradient(to right bottom, #117187, #13859e, #1599b6, #17aecf, #19c3e8);
    height: 24vh;
}

.footerContainer{
    width: 80%;
    margin: auto;
}

.footerTitle{
    color: white;
    font-size: 2rem;
    text-align: center;
    padding-top: 30px;
    text-shadow: 0px 1px 6px rgba( 0, 0, 0, 1);
}

.infoContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
    
}

.contact{
    display: flex;
    margin-top: 40px;
}

.contactItem{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1.1rem;
    margin: 0 56px 0 0;
    color: rgba(255, 255, 255, 0.8);
}

.contactItem p {
    text-shadow: 0px 1px 6px rgba( 0, 0, 0, 1);
}

.contactItem img{
    height: 32px;
}

.socialMediaContainer{
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: flex-end;
}

.socialMediaContainer img{
    height: 40px;
}

@media (max-width:1300px){
    .infoContainer{
        grid-template-columns: 90% 10%;
    }
}

@media(max-width: 1150px){
    .contact{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        justify-content: center;
        align-items: center;    
        margin-top: 20px;
    }
    .contactItem{
        margin:0;
    }
    .infoContainer{
        grid-template-columns: auto auto;
    }
    .footer{
        height: auto;
    }

    .socialMediaContainer{
        align-items: center;
    }
}

@media(max-width: 880px){
    .footerTitle{
        font-size: 1.8rem;
    }
    .footerContainer{
        width: 90%;
    }
}

@media(max-width: 600px){
    .footerContainer{
        width: 98%;
    }
}

@media(max-width: 510px){
    .contact{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .footerTitle{
        font-size: 1.4rem;
    }
}
@media(max-width:415px){
    .infoContainer{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}