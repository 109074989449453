.detailContainer{
    background-color: rgb(245, 245, 245);
}

.detailInfo{
    width: 80%;
    margin: 80px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 0;
}
@media(max-width: 1000px){
    .detailInfo{
        width: 100%;
    }
}

.detailInfo img{
    height: 30%;
    width: 50%;
}

@media(max-width: 650px){
    .detailInfo img{
        width: 70%;
        height: 40%;
    }
    .detailContainer .detailInfo h1{
        width: 80%;
        font-size: 1.8rem;
    }
    .detailContainer .detailInfo .date{
        width: 80%;
    }
    .detailContainer .detailInfo .description{
        width: 80%;
    }
}

@media(max-width:560px){
    .detailInfo h1{
        font-size: 1.6rem;
    }
}

@media(max-width:500px){
    .detailContainer .detailInfo h1{
        width: 90%;
    }
    .detailContainer .detailInfo .date{
        width: 90%;
    }
    .detailContainer .detailInfo .description{
        width: 90%;
    }
    .detailContainer .detailInfo img{
        width: 80%;
    }
}

@media(max-width: 450px){
    .detailContainer .detailInfo h1{
        font-size: 1.4rem;
    }
}
.detailInfo h1{
    width: 70%;
    margin-top: 48px;
    text-align: center;
    color:rgb(17, 113,135)
}
.detailInfo .date{
    color: #828282;
    text-align: start;
    width: 70%;
} 

.detailInfo .description{
    width: 70%;
    margin-top: 24px;
    text-align: justify;
    font-size: 1.2rem;
}
