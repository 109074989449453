.formContainer{
    margin: 48px auto 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    height: 76%;
}

.formTitle{
    color: rgb(17, 113, 135);
    font-size: 2.2rem;
    margin-bottom: 24px;
}
.form{
    margin: 0px auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.form input{
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgb(17, 113, 135);
}

.form label,
.form input{
    width: 40%;
    margin: auto;
}

.form label{
    font-weight: 600;
}

.form button{
    width: 20%;
    margin: 24px auto 0;
}

.error{
    background-color: #f2dede;
    color: #a94442;
    padding: 10px;
}
.loginButton{
    text-decoration: none;
    color: rgb(17, 113, 135);
    font-weight: 600;
}

.ifNotSignup a{
    text-decoration: none;
    color: rgb(17, 113, 135);
    font-weight: 600;
}

@media(max-width: 1000px){
    .form{
        width: 100%;
    }
    .form label,
    .form input{
        width: 80%;
    }
    .form button{
        width: 60%;
    }
}