.field {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
  
.field label {
    color:rgb( 17, 113,135 );
    font-size: 1.2rem;
    font-weight: 600;
}
  
.field input,
.field select{
    width: 100%;
    margin: 0 0 14px;
    font-size: 14px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid grey;
}

.field textarea{
    width: 100%;
    height: 80px;
    margin: 0 0 12px 0;
    border-radius: 4px;
}

.field input::placeholder,
.field textarea::placeholder{
    padding: 0 4px;
}

.field input:focus,
.field textarea:focus{
    padding: 0 4px;
}
.field textarea:focus{
    padding: 4px;

}
.field textarea::placeholder{
    font-size: 1rem;
    padding-top: 4px;
}
  
input[type="submit"] {
    margin: 12px auto;
    border-radius: 4px;
    height: auto;
    padding: 12px;
    font-size: 1.2rem;
    background-color: rgb( 17, 113,135 );
    color: #fff;
    border: none;
    width: 50%;
    cursor: pointer;
}
input[type="submit"]:hover{
    background-color: rgba( 17, 113,135, 0.8 );
    transition: 0.4s
}