.newsContainer{
    margin-top: 48px;
    width: 33%;
    padding: 24px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

@media(max-width: 850px){
    .newsContainer{
        width: 90%;
    }

    .imgContainer{
        width: 90%;
        margin: auto;
    }
    .newsContainer .imgContainer img{
        width: 100%;
        margin: auto;
    }
}

@media(max-width: 400px){
    .newsContainer{
        width: 100%;
    }
}

.imgContainer img{
    width: 100%;
    margin: auto;
}

.newsInfo h4{
    color: rgb(17, 113, 135);
    font-size: 1.2rem;
    text-align: justify;
}
.newsInfo p{
    margin-top: 4px;
    color: #828282;
}

.newsButton{
    background-color: transparent;
    color: rgb(17, 113, 135);
    
}