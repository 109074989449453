.workImage{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../assets/trabajaconnosotro.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 44vh;
}

.workTitle{
    color: rgba( 17, 113,135 , 1);
    font-weight: 600;
    text-align: center;
    margin: 48px auto;
    font-size: 2.2rem;
}

.itemsContainer{
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    margin: 48px 0;
}

.itemsContainer img{
    width: 100%;
    height: 300px;
    margin: auto;
    object-fit: cover;
}

.items{
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    width: 100%;
    margin: 0;
}
.items h2{
    text-align: center;
    font-size: 1.5rem;
    color: rgba( 17, 113,135 , 1);
}
.items p{
    font-size: 1.1rem;
    text-align: justify;
}

.buttonSection{
    width: 80%;
    font-size: 1.3rem;
    margin: auto;
    text-align: center;
}

.buttonLink{  
    width: auto;
    height: auto;
    padding: 12px 12px;
    margin: 24px 0;
    background-color:rgb(17, 113, 135);
    border: 1px solid transparent;
    border-radius: 4px;
    color: white;
    font-size: 1.3rem;
    cursor: pointer;
}
.buttonLink:hover{
    background-color:rgba(17, 113, 135, 0.9);
    transition: 0.4s;
}


@media(max-width:700px){
    .workTitle{
        font-size: 2rem;
    }
    .itemsContainer{
        grid-template-columns: 80%;
    }
    .itemsContainer img{
        height: 200px;
    }
    .buttonSection{
        font-size: 1.2rem;
    }
}

@media(max-width: 400px){
    .workTitle{
        font-size: 1.8rem;
    }
}