/* Timeline.module.css */

.timelineContainer {
    position: relative;
    overflow: hidden;
}
  
.timelineWrapper {
    overflow-x: scroll;
    white-space: nowrap;
    position: relative;
    width: 100%;
}
  
.timeline {
    list-style: none;
    padding: 0;
    margin: 24px 0;
    display: flex;
    gap: 40px;
    padding: 16px;

}
  
.li {
    position: relative;
    width: 50%; /* Ancho de cada elemento li */
    background-color: #f0f0f0; /* Color de fondo de los elementos */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
  
.timestamp {
    background-color: rgb(17, 113,135 ); 
    padding: 10px;
    text-align: center;
}
  
.timelineWrapper .timeline .timestamp .date{
    font-size: 1.2rem;
    color: white;
}
  
.status {
    padding: 10px;
    font-weight: 300;
}

.status ul{
    list-style: none;
}
  
  .scrollButtonLeft,
  .scrollButtonRight {
    background-color: transparent; 
    color: transparent;
  }
  
  .scrollButtonLeft {
    left: 10px;
  }
  
  .scrollButtonRight {
    right: 10px;
  }
  
  .scrollButtonLeft:hover,
  .scrollButtonRight:hover {
    background-color: transparent; 
  }
  
  /* Estilos para el scrollbar */
::-webkit-scrollbar {
    width: 12px;
}
  
::-webkit-scrollbar-thumb {
    background: rgb(17, 113,135 );
}
  
::-webkit-scrollbar-thumb:hover {
    background: rgba(17, 113,135, 0.8 ); 
}
  
::-webkit-scrollbar-track {
    background: #ccc;
    border-radius: 4px;
}
.item{
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeline {
    width: 100%;
    height: auto;
    margin: auto;
    box-shadow: 0px 0px 8px 2px rgba(17, 113,135, 0.4);
    border-radius: 10px;
}

.timeline h3 {
  color: #FFFFFF;
  margin-left: 66px;
  margin-top: 40px;
}

.timeline label {
  margin-left: 66px;
  margin-top: 10px;
  color: #FFFFFF;
}

.timeline .box {
  width: 100%;
  height: auto;
  margin:  24px auto;
}

.timeline .box .container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timeline .box .container .lines {
  margin-left: 40px;
  margin-top: 6px;
}

.timeline .box .container .lines .dot {
  width: 80px;
  height: 80px;
  border:2px solid rgba(17, 113,135);
  border-radius: 50%;
  object-fit: cover;
  margin: 0 12px;
}

.timeline .box .container .lines .line {
  height: 103px;
  width: 2px;
  background: #D1D6E6;
  margin-left: 5.3px;
}

.timeline .box .container .cards {
  margin-left: 12px;
}

.timeline .box .container .cards .card {
  width: 100%;
  height: auto;
  padding: 12px;
  background: #FFFFFF;
  border: 1px solid rgb(17, 113, 135);
  box-shadow: 0 2px 2px 0 #eeeeee40;
  box-shadow: 0px 0px 8px 2px rgba(17, 113,135, 0.2);
  border-radius: 8px;
  margin:0 0 10px 12px;
}

.timeline .box .container .cards .card.mid {
  height: auto;
}

.timeline .box .container .cards .card h4 {
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 5px;
  color: black;
}

.timeline .box .container .cards .card li {
    font-size: 1.1rem ;
    margin-left: 25px;
}

.timeline .box .bottom {
  width: 100%;
  height: 107px;
  background: #fff;
  box-shadow: 0 0 2px #eeeeee50;
  padding-top: 45px;
}

@media(max-width: 440px){
  .timeline .box .container .lines .dot{
    display: none;
  }
}