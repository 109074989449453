
.principalImgContainer{
    display: flex;
    align-items: center;
    height: 92vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../assets/principal.jpg");
    background-repeat: no-repeat;
}

.principalTitle{
    color: rgb(255, 255, 255);
    text-shadow: 0px 1px 6px rgba( 0, 0, 0, 1);
    width: 36%;
    margin-left: 100px;
    font-size: 2.2rem;
}

.infoContainer{
    margin: 48px auto;
}

.aboutFem{
    border-radius: 8px;
    /* border: 2px solid rgba(17, 113, 135, 0.4); */
    background-color: white;
    width: 80%;
    margin: 32px auto;
    padding: 24px 0;

}

.aboutFemTitle{
    font-size: 1.8rem;
    text-align: center;
    margin: 16px auto;
    width: 50%;
}

.aboutFem span,
.mainObjectivesTitle span,
.mapContainer span{
    color: rgb(17, 113, 135);
}

.aboutFem p{
    font-size: 1.3rem;
    text-align: center;
    width: 90%;
    margin: auto;
}

.mainObjectives,
.entrepreneursContainer{
    background-color: rgb(245, 245, 245);
    width: 100%;
    margin: 24px auto 0;
}

.mainObjectivesContainer{
    width: 80%;
    padding: 48px 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.mainObjectivesTitle,
.mapContainer h2{
    font-size: 2.2rem;
}

.mapContainer h2{
    margin-bottom: 48px;
}

.entrepreneurs{
    width: 80%;
    padding: 48px 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 80px;
}

.item{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.item img{
    width: 50px;
}

.item p{
    font-size: 1.2rem;
}

.map{
    width: 100%;
    margin-top: 48px;
}

.mapContainer{
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.mapDataContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dataContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;  
}

.dataItem{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24%;
}

.dataItem img{
    width: 30%;
}

.dataItem h3{
    font-size: 1.6rem;
    margin-bottom: 12px;
}

.dataItem p{
    font-size: 1.3rem;
    text-align: center;
}

.worldMap{
    position: relative;
    width: 100%;    
    height: 60vh;
}
.mapContainer .worldMap img:nth-child(1),
.mapContainer .worldMap img:nth-child(2){
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    
}
.mapContainer .worldMap img:nth-child(1){
    height: 54vh;
}

.worldMapImage{
    opacity: 0;
    background-color: white;
    transition: 1s;
}

.worldMapImage2{
    opacity: 1;
    z-index: 1;
    background-color: white;
    transition: 1s;
}

.worldMapImage:hover, .worldMapImage2:hover, .colombiaMap:hover, .colombiaMap2:hover{
    cursor: pointer;
    transform: scale(1.02);
    transition: 0.6s;
}

.colombiaMap{
    display: none;
    /* opacity: 0; */
    transition: 1s;
    height: 60vh;
}

.colombiaMap2{
    transition: 1s;
    opacity: 1;
    z-index: 1;
    height: 60vh;
}

.itemText{
    color: rgb(17, 113, 135);
    font-weight: 600;
}

.entrepreneurs{
    display: flex;
    flex-direction: column;
}

.entrepreneursTitles h4{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 16px;
}

.entrepreneursTitles h2{
    font-size: 2.2rem;
    font-weight: 600;
    color: rgb(17, 113, 135);
}

.newsContainer{
    width: 80%;
    padding: 48px 0;
    margin: auto;
}

.newsContainer h2{
    font-size: 2.2rem;
    font-weight: 600;
    color: rgb(17, 113, 135);
}



@media(max-width: 1200px){
    .principalTitle{
        width: 50%;
    }
    .aboutFemTitle{
        width: 70%;
    }
    .map{
        margin-top: 24px;
    }
    .mapContainer .worldMap img:nth-child(1){
        height: 45vh;
    }
    .colombiaMap2{
        height: 48vh;
    }

}

@media(max-width: 1000px){
    .dataItem img{
        width: 40%;
    }
    .dataItem h3{
        font-size: 1.4rem;
    }
    .dataItem p{
        font-size: 1.1rem;
    }
    .mapContainer .worldMap img:nth-child(1){
        height: 40vh;
    }
    .colombiaMap2{
        height: 43vh;
    }
}

@media(max-width: 880px){
    .mainObjectivesContainer{
        padding: 24px 0;
    }
    .principalTitle{
        margin: auto;
        text-align: center;
        width: 80%;
    }
    .aboutFemTitle{
        width: 90%;
    }
    .aboutFem p{
        width: 100%;
    }
    .mainObjectivesTitle{
        text-align: center;
    }
    .dataItem h3{
        font-size: 1.2rem;
    }
    .mapContainer .worldMap img:nth-child(1){
        height: 35vh;
    }
    .colombiaMap2{
        height: 38vh;
    }
    .mapContainer h2{
        margin: 0 auto;
    }
}

@media(max-width:840px){
    .dataContainer{
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr 1fr;
    }
    .dataItem{
        width: 80%;
        margin: auto;
    }
    .dataItem img{
        width: 30%;
    }
    .entrepreneursTitles h4{
        text-align: center;
    }
    
    .entrepreneursTitles h2{
        text-align: center;
    }
    .mapContainer{
        width: 100%;
    }
    .blogTitle{
        text-align: center;
    }
    
}

@media(max-width: 780px){
    
    .mapContainer h2{
        font-size: 2rem;
    }
    .worldMap{
        height: 40vh;
    }
}

@media(max-width: 670px){
    .mapContainer .worldMap img:nth-child(1){
        height: 30vh;
    }
    .colombiaMap2{
        height: 33vh;
    }
}

@media(max-width: 547px){
    .mainObjectivesContainer{
        gap: 0;
    }
    .principalTitle{
        font-size: 1.8rem;
    }
    .infoContainer{
        margin: 0px auto;
    }
    .aboutFemTitle{
        font-size: 1.5rem;
        width: 100%;
    }
    .aboutFem p{
        font-size: 1.2rem;
    }
    .mainObjectivesTitle{
        font-size: 1.8rem;
    }
    .mapContainer h2{
        font-size: 1.5rem;
    }
    .entrepreneursTitles h4{
        font-size: 1.3rem;
    }
    
    .entrepreneursTitles h2{
        font-size: 2rem;
    }
    .mapContainer .worldMap img:nth-child(1){
        height: 25vh;
    }
    .colombiaMap2{
        height: 28vh;
    }  
}
@media(max-width: 460px){
    .dataItem{
        text-align: center;
    }
    .dataContainer{
        grid-template-columns: 80%;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
    
    .mapContainer h2{
        width: 100%;
        text-align: center;
    }
    .entrepreneursTitles h2{
        font-size: 1.8rem;
    }
    .mapContainer .worldMap img:nth-child(1){
        height: 20vh;
    }
    .colombiaMap2{
        height: 23vh;
    }  
}

@media(max-width: 445px){
    
    .principalTitle{
        width: 90%;
        font-size: 1.6rem;
    }
    .aboutFemTitle{
        font-size: 1.2rem;
    }
    .aboutFem p{
        font-size: 1rem;

    }
    .mainObjectivesTitle{
        font-size: 1.6rem;
    }
    .worldMap{
        height: 28vh;
    }
}

@media(max-width:380px){
    .mapContainer .worldMap img:nth-child(1){
        height: 18vh;
    }
    .colombiaMap2{
        height: 21vh;
    }  
}