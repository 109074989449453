  /* nav-icon-5 */
  .navIcon{
    width: 35px;
    height: 30px;
    margin: 10px 10px;
    position: relative;
    cursor: pointer;
    display: inline-block;
  }
  .navIcon span{
    background-color: rgb(17, 113, 135);
    position: absolute;
    border-radius: 2px;
    transition: .3s cubic-bezier(.8, .5, .2, 1.4);
    width:100%;
    height: 4px;
    transition-duration: 500ms
  }
  .navIcon span:nth-child(1){
    top:0px;
    left: 0px;
  }
  .navIcon span:nth-child(2){
    top:13px;
    left: 0px;
    opacity:1;
  }
  .navIcon span:nth-child(3){
    bottom:0px;
    left: 0px;
  }
  .navIcon:not(.open):hover span:nth-child(1){
    transform: rotate(-3deg) scaleY(1.1);
  }
  .navIcon:not(.open):hover span:nth-child(2){
    transform: rotate(3deg) scaleY(1.1);
  }
  .navIcon:not(.open):hover span:nth-child(3){
    transform: rotate(-4deg) scaleY(1.1);
  }
  .navIcon.open span:nth-child(1){
    transform: rotate(45deg);
    top: 13px;
  }
  .navIcon.open span:nth-child(2){
    opacity:0;
  }
  .navIcon.open span:nth-child(3){
    transform: rotate(-45deg);
    top: 13px;
  }


  
  

 