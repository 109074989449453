.aboutUsSection{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../assets/aboutUs.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height:44vh;
}

.aboutUsTitleContainer{
    width: 80%;
    margin: 48px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.aboutUsTitleContainer p{
    text-align: justify;
    margin: 20px 0;
}

.aboutUsTitle{
    color: rgb( 17, 113,135 );
    text-align: center;
    margin: 32px auto;
    font-size: 2.2rem;
}

.aboutitem1 h2,
.aboutitem2 h2 {
    color: rgb( 17, 113,135 );
    font-size: 2rem;
    margin: 12px 0;
}

.aboutUsTitleContainer p {
    font-size: 1.3rem;
}

.aboutitem1{
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 60px;
}

.aboutitem1 img{
    width: 50%;
}

.aboutitem2{
    margin: 40px 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 60px;
}

.aboutitem2 img{
    width: 100%;
}

.aboutContact{
    width: 80%;
    text-align: center;
    margin:auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    font-size: 1.3rem;
}

.aboutContact a button{
    margin-bottom: 48px;
    width: auto;
    padding: 8px;
    height: auto;
    border-radius: 8px;
    background-color: rgb(17, 113, 135);
    border: 1px solid rgb(17, 113, 135);
    color: white;
    font-size: 1.3rem;
}

.aboutContact button:hover{
    cursor: pointer;
    background-color: rgba(17, 113, 135, 0.8);
}
.aboutUsTitleContainer span,
.aboutContact span{
    color: rgb(17, 113, 135);
    font-weight: 600;
}

@media(max-width: 1300px){
    .aboutUsTitleContainer{
        width: 90%;
    }
}

@media(max-width: 1100px){
    .aboutitem1,
    .aboutitem2{
        flex-direction: column;
        gap: 20px;
    }
    .aboutitem1 h2,
    .aboutitem2 h2{
        text-align: center;
    }
    .aboutitem1 img,
    .aboutitem2 img{
        width: 80%;
        height: 400px;
    }
    .aboutUsTitleContainer{
        margin-bottom: 0;
    }

}

@media(max-width: 800px){
    .aboutitem1 p,
    .aboutitem2 p,
    .aboutUsTitleContainer p,
    .aboutContact p{
        font-size: 1.1rem;
    }
    .aboutUsTitleContainer h1,
    .aboutitem1 h2,
    .aboutitem2 h2{
        font-size: 2rem;
    }
    .aboutUsTitleContainer h1{
        margin-bottom: 16px;
    }

    .aboutitem1,
    .aboutitem2{
        margin: 20px 0;
    }
    .aboutitem1 img,
    .aboutitem2 img{
        width: 80%;
        height: 300px;
    }
}
@media(max-width: 600px){
    .aboutUsTitleContainer{
        margin: 16px auto;

    }
    .aboutContact button{
        width: 40%;
    }
    .aboutUsTitleContainer h1,
    .aboutitem1 h2,
    .aboutitem2 h2{
        font-size: 1.8rem;
    }
    .aboutitem1 img,
    .aboutitem2 img{
        width: 80%;
        height: 200px;
    }
}
@media(max-width: 400px){
    .aboutUsTitleContainer h1,
    .aboutitem1 h2,
    .aboutitem2 h2{
        font-size: 1.6rem;
    }
    .aboutitem1 img,
    .aboutitem2 img{
        width: 100%;
        height: 200px;
    }
    .aboutContact{
        width: 90%;
    }
    .aboutContact button{
        width: 50%;
    }
}