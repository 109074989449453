.sidebar{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgb(17, 113, 135);
    width: 14rem;
    height: calc(100vh - 84px);
    border-right: 1px solid gray;

}

.sidebar ul{
    padding: 1rem 2rem;
    list-style: none;
}

.sidebar li{
    margin-bottom: 1rem;
}

.sidebar a{
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
}

.logoutButton{
    color: red;
    font-size: 1.8rem;
}